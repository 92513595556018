<template>
  <div class="video">
      <video :id="id" class="video-js vjs-default-skin" controls playsinline autoplay="autoplay" width="500px">
        <source :src="url1" type="application/x-mpegURL" />
      </video>
  </div>
</template>
<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
  data() {
    return {
      id: "videoPlayer",
      url1: "",
      dp: null,
      options: {
        autoplay: false, // 设置自动播放
        muted: false, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
        preload: "auto", // 预加载
        controls: true, // 显示播放的控件
      },
      data: [],
    };
  },
  methods: {
    loadVideo() {
      this.db = videojs("videoPlayer", this.options);
    },
    // 销毁
    beforeDestroy() {
      if (this.dp) {
        this.dp.dispose(); // dispose()会直接删除Dom元素
      }
    },
    handleVideo() {
      this.url1 = "http://pili-live-hls.tjxirang.com/mmdzhibo/mmd5mmd6.m3u8";
      this.$nextTick(() => {
        this.loadVideo();
      });
    },
  },
  mounted() {
    // 使用 $nextTick 解决vedio报错 The element or ID supplied is not valid. (videojs)
    this.handleVideo();
  },
};
</script>
<style lang="scss" scoped>
.video::v-deep {
  overflow: auto !important;
  background: rgba(41, 85, 252, 0.18);
  border-radius: 2px;
  box-shadow: inset 2px 2px 14px rgba(135, 211, 255, 0.3);
  position: relative;
  height: 100vh;
  .video-js {
    width: 100%;
    height: 100%;
  }

  .video-box {
    width: 100%;
    max-width: 500px;
    max-height: 500px;
  }

  // 暂停播放按钮居中
  ::v-deep .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>