import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 1 引入element-ui.js
import ElementUI from "element-ui";
// 2 引入element-ui.css  注意在vue中引入css加@,在js中引入css不加@
import "element-ui/lib/theme-chalk/index.css";
// 3 在vue实例中使用ElementUI
Vue.use(ElementUI);

// 一 引入axios
import axios from "axios";
// 二 将axios放到vue实例的原型中使用
Vue.prototype.$http=axios

// 一 引入VueAxios
import VueAxios from "vue-axios";
// 二 在vue实例中使用
Vue.use(VueAxios,axios)

import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
